/* eslint-disable */
import React, { useState } from 'react';

const RteHeader = () => {
  const [display, setDisplay] = useState(false)
  const handleClick = () => {
    setDisplay(!display);
  }

  const menuStyle = {
    position: 'absolute',
    background: '#1a1e24',
    width: '100%',
    marginLeft: '-35px',
    marginTop: '5px',
    padding: '20px',
    display: display ? 'block' : 'none'
  }

  return (
    <div class="masthead rte_foundation">
      <div id="static-nav" class="row nav-header">
        <div>
          <div class="hamburger-container" id="more-btn-mob">
          <a class="menu nav-btn" onClick={handleClick} data-trackevent-action="menu_icon" data-trackevent-label="hamburger" id="menu-icon-mob" role="button" aria-label="Additional links" tabindex="0">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          </a>
          </div>
          <div class="rte-logo-container col columns medium-6">
          <a href="https://www.rte.ie/" id="rte-logo" data-trackevent-action="menu_icon" data-trackevent-label="logo" data-ati-tracking="{&quot;campaign&quot;:&quot;header&quot;,&quot;generalPlacement&quot;:&quot;logo&quot;,&quot;creation&quot;:&quot;top&quot;, &quot;variant&quot;:&quot;0&quot;, &quot;format&quot;:&quot;nav&quot;, &quot;url&quot;:&quot;Logo&quot;, &quot;detailedPlacement&quot;:&quot;&quot;}" role="link" aria-label="Ireland's National Public Service Media | Meáin Náisiúnta Seirbhíse Poiblí na hÉireann" title="Ireland's National Public Service Media | Meáin Náisiúnta Seirbhíse Poiblí na hÉireann" tabindex="0">
          <i class="dotie-icon_logo_rte"></i>
          <span class="english-title">Ireland's National Public Service Media</span>
          <span class="irish-title">Meáin Náisiúnta Seirbhíse Poiblí na hÉireann</span>
          </a>
          </div>
        </div>
        <div>
          <nav class="nav" style={menuStyle} id="primary-nav-mobile">
            <ul>
            <li style={{padding: '5px 0'}}><a href="https://www.rte.ie/news/" class="news-nav-item first ">News</a></li>
            <li style={{padding: '5px 0'}}><a href="https://www.rte.ie/sport/" class="sport-nav-item ">Sport</a></li>
            <li style={{padding: '5px 0'}}><a href="https://www.rte.ie/entertainment/" class="entertainment-nav-item ">Entertainment</a></li>
            <li style={{padding: '5px 0'}} class="business-nav-item"><a href="https://www.rte.ie/news/business/" class="business-nav-item ">Business</a></li>
            <li style={{padding: '5px 0'}}><a href="https://www.rte.ie/lifestyle/" class="lifestyle-nav-item ">Lifestyle</a></li>
            <li style={{padding: '5px 0'}}><a href="https://www.rte.ie/culture/" class="culture-nav-item ">Culture</a></li>
            <li style={{padding: '5px 0'}}><a class="player-nav-item" href="https://www.rte.ie/player/">Player</a></li>
            <li style={{padding: '5px 0'}}><a class="tv-nav-item" href="https://www.rte.ie/tv/">TV</a></li>
            <li style={{padding: '5px 0'}}><a class="radio-nav-item" href="https://www.rte.ie/radio/">Radio</a></li>
            </ul>
          </nav>
        </div>
      </div>
        <div id="compact-nav" class="" role="menu">
        <div class="row nav-header">
        <div class="col columns medium-9 large-8">
        <div class="rte-logo-container">
        <a href="https://www.rte.ie/" id="rte-logo" data-trackevent-action="menu_icon" data-trackevent-label="logo" data-ati-tracking="{&quot;campaign&quot;:&quot;header&quot;,&quot;generalPlacement&quot;:&quot;logo&quot;,&quot;creation&quot;:&quot;top&quot;, &quot;variant&quot;:&quot;0&quot;, &quot;format&quot;:&quot;nav&quot;, &quot;url&quot;:&quot;Logo&quot;, &quot;detailedPlacement&quot;:&quot;&quot;}">
        <i class="dotie-icon_logo_rte"></i>
        </a>
      </div>
      <nav class="nav" id="primary-nav">
      <ul>
      <li><a href="https://www.rte.ie/news/" class="news-nav-item first ">News</a></li>
      <li><a href="https://www.rte.ie/sport/" class="sport-nav-item ">Sport</a></li>
      <li><a href="https://www.rte.ie/entertainment/" class="entertainment-nav-item ">Entertainment</a></li>
      <li class="business-nav-item"><a href="https://www.rte.ie/news/business/" class="business-nav-item ">Business</a></li>
      <li><a href="https://www.rte.ie/lifestyle/" class="lifestyle-nav-item ">Lifestyle</a></li>
      <li><a href="https://www.rte.ie/culture/" class="culture-nav-item ">Culture</a></li>
      <li><a class="player-nav-item" href="https://www.rte.ie/player/">Player</a></li>
      <li><a class="tv-nav-item" href="https://www.rte.ie/tv/">TV</a></li>
      <li><a class="radio-nav-item" href="https://www.rte.ie/radio/">Radio</a></li>
      </ul>
      </nav>
      </div>
      </div>
      </div>
    </div>
  )
};

export default RteHeader;
